export default {
    name: 'SolutionAni',
    data: function () {
        return {
            activeTopSolution: 0,
            topSolutionImages: ['top-guide.svg', 'top-goal.svg', 'top-badge.svg', 'top-story.svg']
        }
    },
    methods: {
        solutionFaderInterval: function () {
            setInterval(() => {
                this.activeTopSolution++
                if (this.activeTopSolution >= this.topSolutionImages.length) this.activeTopSolution = 0
            }, 3000)
        },
        getStartIllu: function () {
            if (window.innerWidth > 1800 || window.innerWidth < 600) {
                return 'start-giant'
            } else {
                return 'start'
            }
        },
    },
    created: function () {
        this.solutionFaderInterval()
    }
}