import {
    ADD,
    UPDATE,
    db
} from '@/firebase.js'

import Stepper from '@/components/Stepper/Stepper.vue'
import LeadCard from '@/components/LeadCard/LeadCard.vue'
import SolutionAni from '@/components/SolutionAni/SolutionAni.vue'
import FAQ from '@/components/FAQ/FAQ.vue'
import Founders from '@/components/Founders/Founders.vue'

export default {
    name: 'Students',
    components: {
        Stepper,
        LeadCard,
        SolutionAni,
        FAQ,
        Founders
    },
    metaInfo() {
        return {
            title: "Gokind - Apply to be a Student Council Member!",
            meta: [{
                    vmid: 'description',
                    name: 'description',
                    content: 'A paid side-gig with an impact. Help us make companies speak up about sustainability and equality.'
                }, {
                    vmid: 'og:image',
                    name: 'og:image',
                    content: require('@/assets/img/students/og-students.png')
                }, {
                    vmid: 'og:logo',
                    name: 'og:logo',
                    content: require('@/assets/img/shared/favicon-light.png')
                }, {
                    vmid: 'og:description',
                    name: 'og:description',
                    content: 'A paid side-gig with an impact. Help us make companies speak up about sustainability and equality.'
                },
                {
                    vmid: 'og:url',
                    name: 'og:url',
                    content: 'https://gokind.co/students/'
                },
                {
                    vmid: 'og:title',
                    property: 'og:title',
                    content: "Apply to be a Student Council Member!"
                },
            ]
        }
    },
    data: function () {
        return {
            items: [{
                question: 'How many hours a week am I expected to work?',
                answer: 'The job is gig based, which means that we will always discuss and agree on the number of hours to be spent before start. Rest assured - it’s adapted to fit the stressful life of a student.'
            }, {
                question: 'Do I have to be a student?',
                answer: 'We would recommend that you are. You can apply irrespective of the program or progress of your studies.'
            }, {
                question: 'Is the work fully remote or am I supposed to be at the office?',
                answer: "It's designed for remote work, but if you happen to live in Stockholm, office visits are more than welcome!"
            }, {
                question: 'Do I have to be a sustainability expert?',
                answer: 'Nope. We want your fresh insights and opinions. We also give you training and seminars to boost your understanding of important topics relevant for the projects.'
            }],
            showBotBtns: false,
            applyFormStep: 1,
            data: {
                name: null,
                email: null,
                tel: null,
                university: null,
                program: null,
                year: null,
                pitch: null,
                linkedin: null,
            },
            applicationId: null,
            sendingToDb: false,
            steps: [{
                    title: 'Analyze current work.',
                    desc: 'Conclude and share insights.',
                    img: 'students-step-1.svg'
                },
                {
                    title: 'Find highlights',
                    desc: 'Call attention to the best work.',
                    img: 'students-step-2.svg'
                }, {
                    title: 'Identify fluff',
                    desc: 'Find shallow and risky content.',
                    img: 'students-step-3.svg'
                }, {
                    title: 'Set the north star',
                    desc: 'Show where to go next.',
                    img: 'students-step-4.svg'
                }
            ],
            leadCards: [{
                    title: 'Connect with companies',
                    desc: 'Advice trailblazers within the sustainability sector.',
                    img: 'benefits-connect.svg',
                    bgColor: '#F6F3FF',
                    textColor: '#675B8C',

                },
                {
                    title: 'Do meaningful work',
                    desc: 'Not your regular 9 to 5. Drive innovation and inspiration.',
                    img: 'benefits-meaning.svg',
                    bgColor: '#FFEEEE',
                    textColor: '#8A5B5B',
                },
                {
                    title: 'Get compensated',
                    desc: 'We believe great work deserves a pay.',
                    img: 'benefits-money.svg',
                    bgColor: '#EFF6F3',
                    textColor: '#687D74',
                }
            ],
            copied: false,
        }
    },
    methods: {
        formSubmittion: function () {
            if (!this.stepIsValid()) return
            if (this.applyFormStep === 1) {
                this.sendInitalDbInfo()
            } else if (this.applyFormStep === 2) {
                this.updateDbInfo({
                    university: this.data.university,
                    program: this.data.program,
                    year: parseInt(this.data.year)
                })
            } else if (this.applyFormStep === 3) {
                this.updateDbInfo({
                    pitch: this.data.pitch
                })
            } else if (this.applyFormStep === 4) {
                this.updateDbInfo({
                    linkedin: this.data.linkedin
                })
            }
        },
        copyReferralLink: function () {
            console.log('Copying to clipboard')
            const el = document.createElement('textarea');
            el.value = 'https://gokind.co/students?r=1';
            el.setAttribute('readonly', '');
            el.style.position = 'absolute';
            el.style.left = '-9999px';
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
            this.copied = true
        },
        hideApplyForm: function () {
            if ((!this.data.email && !this.data.name && !this.data.tel) || this.applyFormStep > 3) {
                this.$router.push({
                    query: {}
                })
            } else if (confirm("Are you sure you want to exit? No progress will be saved.")) {
                this.$router.push({
                    query: {}
                })
            }

        },
        stepIsValid: function () {
            if (this.applyFormStep === 1) {
                return (this.data.name && this.data.email && this.data.tel)
            } else if (this.applyFormStep === 2) {
                return (this.data.university && this.data.program && this.data.year)
            } else if (this.applyFormStep === 3) {
                return this.data.pitch
            } else if (this.applyFormStep === 4) {
                return this.data.linkedin
            }
        },
        makeFormVisible: function () {
            this.$router.push({
                query: {
                    applyForm: '1',
                    r: this.$route.query.r ? 1 : 0
                }
            })
            this.applyFormStep = 1
            this.data = {
                name: null,
                email: null,
                tel: null,
                university: null,
                program: null,
                pitch: null,
                linkedin: null,
            }
        },
        sendInitalDbInfo: function () {
            this.sendingToDb = true
            let collection = db.collection('applications')
            ADD(collection, {
                name: this.data.name,
                email: this.data.email,
                tel: this.data.tel,
                date: new Date(),
                referred: (this.$route.query.r === '1' ? true : false)
            }).then(doc => {
                this.applicationId = doc.id
                this.applyFormStep = 2
                this.sendingToDb = false
            })
        },
        updateDbInfo: function (info) {
            this.sendingToDb = true
            let doc = db.collection('applications').doc(this.applicationId)
            UPDATE(doc, info).then(() => {
                this.applyFormStep++
                this.sendingToDb = false
            })
        },
        getQuoteIllu: function () {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return 'boat-mobile'
            } else if (window.innerWidth > 1800) {
                return 'boat-short'
            } else {
                return 'boat-short'
            }
        },
        getFooterIllu: function () {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return 'footer-illu-mobile'
            } else if (window.innerWidth > 1800) {
                return 'footer-illu-giant'
            } else {
                return 'footer-illu3'
            }
        }
    },
    created: function () {
        document.querySelector('body').classList.add("students");
    },
    destroyed: function () {
        document.querySelector('body').classList.remove("students");
    }
}